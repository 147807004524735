import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {FormHelperText} from "@mui/material";
import { GeocodingAutocomplete } from '../../GeocodingAutocomplete/GeocodingAutocomplete';

const QuotesNewAddBusinessPartnerModal = (props) => {
  const {
    isCustomer,
    open,
    onClose,
    onInputBlur,
    onInputChange,
    onSubmit,
    inputFields,
    isLoading,
    onModalUserNewClose,
  } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiDialog-paper': {
        minWidth: 420,
      },
    },
    header: {
      marginBottom: theme.spacing(6),
    },
    footer: {
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    textbox: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    spinner: {
      display: 'none',
      position: 'absolute',
    },
    spinnerLoading: {
      display: 'inline-block',
    },
    ctaLoading: {
      color: 'transparent !important',
    },
    address: {
      marginBottom: 30,
    },
    wrapperInput: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    houseNumber: {
      width: "60%",
    },
    postcode: {
      width: "38%",
    },
    city: {
      width: "32%",
    },
    district: {
      width: "32%",
    },
    county: {
      width: "32%",
    },
    state: {
      width: "60%",
    },
    stateCode: {
      width: "38%",
    },
    country: {
      width: "60%",
    },
    countryCode: {
      width: "38%",
    },
  }));

  const classes = useStyles();

  const handleChangeAddress = (value) => {
    const properties = value?.properties;

    [
      properties?.street ?? "",
      properties?.housenumber ?? "",
      properties?.postcode ?? "",
      properties?.city ?? "",
      properties?.district ?? "",
      properties?.county ?? "",
      properties?.state ?? "",
      properties?.state_code ?? "",
      properties?.country ?? "",
      properties?.country_code ?? "",
    ].forEach((value, index) => onInputChange({ currentTarget: { value } }, isCustomer ? index + 6 : index + 5));
  };

  const handleSubmit = () => {
    onSubmit();
  };

  const renderInputField = (fieldProps, index) => {
    switch (fieldProps.type) {
      case "address": {
        return (
          <FormControl className={clsx(classes.textbox, classes.address)} style={{ position: "relative" }}>
            <GeocodingAutocomplete
              label="Find address"
              placeholder="Find address"
              onChange={handleChangeAddress}
            />
            {fieldProps.error && <FormHelperText>{fieldProps.helperText}</FormHelperText>}
          </FormControl>
        );
      }
      case "select-one": {
        const attributes = {
          ...(fieldProps.error && { error: true }),
          ...(fieldProps.helperText && { helperText: fieldProps.helperText }),
        };
        return (
          <FormControl className={classes.textbox} error={fieldProps.error}>
            <InputLabel htmlFor={fieldProps.id}>{fieldProps.label}</InputLabel>
            <Select
              native
              label={fieldProps.label}
              defaultValue=""
              value={fieldProps.value}
              onBlur={(event) => onInputBlur(event, index)}
              onChange={(event) => onInputChange(event, index)}
              inputProps={{
                key: fieldProps.id,
                id: fieldProps.id,
                name: fieldProps.id,
                required: true,
                ...attributes,
              }}
            >
              <option aria-label="None" value="" disabled></option>
              {fieldProps?.options?.map((option) => {
                return (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                );
              })}
            </Select>
            {fieldProps.error && <FormHelperText>{fieldProps.helperText}</FormHelperText>}
          </FormControl>
        );
      }
      default: {
        const attributes = {
          ...(fieldProps.type && { type: fieldProps.type }),
          ...(fieldProps.error && { error: true }),
          ...(fieldProps.helperText && { helperText: fieldProps.helperText }),
        };
        const inputClasses = classes[fieldProps.id] ?? "";
        return (
          <TextField
            {...attributes}
            className={clsx(classes.textbox, inputClasses)}
            key={fieldProps.id}
            id={fieldProps.id}
            name={fieldProps.id}
            value={fieldProps.value}
            label={fieldProps.label}
            onBlur={(event) => onInputBlur(event, index)}
            onChange={(event) => onInputChange(event, index)}
            InputLabelProps={{
              shrink: true,
            }}
            required={fieldProps.required}
          />
        );
      }
    }
  }

  return (
    <Dialog open={open} onClose={onModalUserNewClose} className={classes.root}>
      <DialogTitle id="alert-dialog-title">Add new {isCustomer ? 'customer' : 'intermediary'}</DialogTitle>
      <DialogContent>
        <Box color="text.secondary" className={classes.header}>
          <Typography variat="p" color="inherit">
            Your new {isCustomer ? 'customer' : 'intermediary'} will be added to Riskwolf for future use.
          </Typography>
        </Box>
        <Box className={classes.wrapperInput}>
        {inputFields.map((inputField, index) => renderInputField(inputField, index))}
        </Box>
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disableElevation
          {...(isLoading && {
            className: classes.ctaLoading,
            disabled: true,
          })}
        >
          <CircularProgress
            className={clsx(classes.spinner, {
              [classes.spinnerLoading]: isLoading,
            })}
            size={16}
          />
          Add {isCustomer ? 'customer' : 'intermediary'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuotesNewAddBusinessPartnerModal;
