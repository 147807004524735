

import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CalculateIcon from "@mui/icons-material/Calculate";
import PushPinIcon from "@mui/icons-material/PushPin";
import TimelineIcon from "@mui/icons-material/Timeline";

import i18n from "../i18n";
import ToolButton from "../components/ToolButton";
import ControlBarItem from "./ControlBarItem";
import { isUserVariable } from "../model/userVariable";

export default function VariableSelect({
  selectedDatasetId,
  selectedVariableName,
  selectedDataset2Id,
  selectedVariable2Name,
  variables,
  canAddTimeSeries,
  addTimeSeries,
  selectVariable,
}) {
  const handleVariableChange = (event) => {
    selectVariable(event.target.value || null);
  };

  const handleAddTimeSeriesButtonClick = () => {
    addTimeSeries();
  };

  const variableSelectLabel = (
    <InputLabel shrink htmlFor="variable-select">
      Metric
    </InputLabel>
  );

  const variableSelect = (
    <Select
      variant="standard"
      value={selectedVariableName || ""}
      onChange={handleVariableChange}
      input={<Input name="variable" id="variable-select" />}
      displayEmpty
      name="variable"
      renderValue={() =>
        getVariableLabel(variables.find((v) => v.name === selectedVariableName))
      }
    >
      {(variables || []).map((variable) => (
        <MenuItem
          key={variable.name}
          value={variable.name}
          selected={variable.name === selectedVariableName}
        >
          {isUserVariable(variable) && (
            <ListItemIcon>
              <CalculateIcon fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText>{getVariableLabel(variable)}</ListItemText>
          {selectedDatasetId === selectedDataset2Id &&
            variable.name === selectedVariable2Name && (
              <PushPinIcon fontSize="small" color="secondary" />
            )}
        </MenuItem>
      ))}
    </Select>
  );
  const addTimeSeriesButton = (
    <ToolButton
      key={"timeSeries"}
      disabled={!canAddTimeSeries}
      onClick={handleAddTimeSeriesButtonClick}
      tooltipText={i18n.get("Show time-series diagram")}
      icon={<TimelineIcon />}
    />
  );

  return (
    <ControlBarItem
      label={variableSelectLabel}
      control={variableSelect}
      actions={[addTimeSeriesButton]}
    />
  );
}

function getVariableLabel(variable) {
  if (!variable) {
    return "?";
  }
  return variable.title || variable.name;
}
