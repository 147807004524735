import { useEffect, useState } from "react";
import { useTheme } from "@mui/system";
import { default as OlGeoJSONFormat } from "ol/format/GeoJSON";
import { default as OlCircleGeometry } from "ol/geom/Circle";
import { fromCircle as olPolygonFromCircle } from "ol/geom/Polygon";
import { default as OlVectorLayer } from "ol/layer/Vector";
import { default as OlVectorSource } from "ol/source/Vector";
import { default as OlTileLayer } from "ol/layer/Tile";
import { default as OlCircleStyle } from "ol/style/Circle";
import { default as OlFillStyle } from "ol/style/Fill";
import { default as OlStrokeStyle } from "ol/style/Stroke";
import { default as OlStyle } from "ol/style/Style";
import { getRenderPixel } from "ol/render";
import { Overlay } from "ol";

import i18n from "../../i18n";
import { getUserPlaceColor, getUserPlaceColorName, getUserPlaceFillOpacity } from "../../config";
import { USER_DRAWN_PLACE_GROUP_ID, USER_ID_PREFIX } from "../../model/place";
import { MAP_OBJECTS } from "../../states/controlState";
import { newId } from "../../util/id";
import { GEOGRAPHIC_CRS } from "../../model/proj";
import UserVectorLayer from "../../components/UserVectorLayer";
import ErrorBoundary from "../../components/ErrorBoundary";
import { ScaleLine } from "../ol/control/ScaleLine";
import { Draw } from "../ol/interaction/Draw";
import { Layers } from "../ol/layer/Layers";
import { Vector } from "../ol/layer/Vector";
import { Map } from "../ol/Map";
import { View } from "../ol/View";
import { setFeatureStyle } from "../ol/style";
import { findMapLayer } from "../ol/util";
import { isNumber } from "../../util/types";
import { makeStyles } from "@mui/styles";
import { useSidebarContext } from "../../../../Contexts/SidebarContext";
import {formatNumber} from "../../../../Util/formatNumber";

const SELECTION_LAYER_ID = "selection";
const SELECTION_LAYER_SOURCE = new OlVectorSource();

// TODO (forman): move all map styles into dedicated module,
//  so settings will be easier to find & adjust

const SELECTION_COLOR = [255, 220, 0, 0.8];

const SELECTION_LAYER_STROKE = new OlStrokeStyle({
  color: SELECTION_COLOR,
  width: 10,
  lineCap: "square",
  lineDash: [10, 15],
});

const SELECTION_LAYER_FILL = new OlFillStyle({
  color: [0, 0, 0, 0],
});

const SELECTION_LAYER_STYLE = new OlStyle({
  stroke: SELECTION_LAYER_STROKE,
  fill: SELECTION_LAYER_FILL,
  image: new OlCircleStyle({
    radius: 15,
    stroke: new OlStrokeStyle({
      color: SELECTION_COLOR,
      width: 6,
      lineCap: "square",
      lineDash: [6, 6],
    }),
    fill: SELECTION_LAYER_FILL,
  }),
});

const useStyles = makeStyles((theme) => ({
  popupContainer: {
    position: "absolute",
    backgroundColor: "#1f1f1f",
    borderRadius: "8px",
    padding: "10px",
    color: "white",
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.5)",
    width: 170,
    zIndex: 99,
    transform: "translate(-10%, -140%)",
    display: (props) => {
      return props.a ? "block" : "none";
    },
  },
  popupArrow: {
    content: '""',
    position: "absolute",
    bottom: "-16px",
    left: "8%",
    transform: "translateX(-50%)",
    borderWidth: "10px",
    borderStyle: "solid",
    borderColor: "#1f1f1f transparent transparent transparent",
  },
  popupContent: {
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
}));

export default function Viewer({
  theme,
  mapId,
  mapInteraction,
  mapProjection,
  baseMapLayer,
  rgb2Layer,
  rgbLayer,
  variable2Layer,
  variableLayer,
  datasetBoundaryLayer,
  placeGroupLayers,
  overlayLayer,
  colorBarLegend,
  colorBarLegend2,
  mapSplitter,
  mapPointInfoBox,
  userDrawnPlaceGroupName,
  addDrawnUserPlace,
  importUserPlacesFromText,
  userPlaceGroups,
  userPlaceGroupsVisibility,
  showUserPlaces,
  selectPlace,
  selectedPlaceId,
  places,
  imageSmoothing,
  variableSplitPos,
  onMapRef,
  selectedPlaceInfo,
  setSidebarOpen,
  setSidebarPanelId,
}) {
  theme = useTheme();
  const [isPopupShow, setIsPopupShow] = useState(false);
  const { isOpen } = useSidebarContext();

  const classes = useStyles({ a: isPopupShow });
  const [map, setMap] = useState(null);
  const [popup, setPopup] = useState(null);
  const [selectedPlaceIdPrev, setSelectedPlaceIdPrev] = useState(selectedPlaceId || null);

  useEffect(() => {
    if (map) {
      const timeoutId = setTimeout(() => {
        map.updateSize();
      }, theme.transitions.duration.leavingScreen);

      return () => clearTimeout(timeoutId);
    }
  }, [isOpen, map, theme.transitions.duration.leavingScreen]);

  useEffect(() => {
    let popupOverlay;
    if (map) {
      const container = document.getElementById("popup");

      popupOverlay = new Overlay({
        element: container,
      });

      map.addOverlay(popupOverlay);
      setPopup(popupOverlay);
    }

    return () => {
      if (popupOverlay) {
        map.removeOverlay(popupOverlay)
      }
    }
  }, [map]);

  useEffect(() => {
    if (map && popup) {
      if (selectedPlaceId) {
        const selectedFeature = findFeatureById(map, selectedPlaceId);

        if (selectedFeature) {
          const coordinates = selectedFeature.getGeometry().getCoordinates();
          setSidebarOpen(true)
          setSidebarPanelId('timeSeries')
          popup.setPosition(coordinates);
          setIsPopupShow(true);
        } else {
          popup.setPosition(undefined);
          setIsPopupShow(false);
          setSidebarOpen(false)
        }
      } else {
        popup.setPosition(undefined);
        setIsPopupShow(false);
        setSidebarOpen(false)
      }
    }
  }, [map, selectedPlaceId, popup, setSidebarOpen]);

  useEffect(() => {
    if (map) {
      const selectedPlaceIdCurr = selectedPlaceId || null;
      if (selectedPlaceIdCurr !== selectedPlaceIdPrev) {
        if (MAP_OBJECTS[SELECTION_LAYER_ID]) {
          const selectionLayer = MAP_OBJECTS[SELECTION_LAYER_ID];
          const selectionSource = selectionLayer.getSource();
          selectionSource.clear();
          if (selectedPlaceIdCurr) {
            const selectedFeature = findFeatureById(map, selectedPlaceIdCurr);
            if (selectedFeature) {
              // We clone features, so we can set a new ID and clear the style, so the selection
              // layer style is used instead as default.
              const displayFeature = selectedFeature.clone();
              displayFeature.setId("select-" + selectedFeature.getId());
              displayFeature.setStyle(undefined);
              selectionSource.addFeature(displayFeature);
            }
          }
          setSelectedPlaceIdPrev(selectedPlaceIdCurr);
        }
      }
    }
  }, [map, selectedPlaceId, selectedPlaceIdPrev]);

  // If imageSmoothing changed, notify all tile layers.
  useEffect(() => {
    // Force layer source updates after imageSmoothing change
    if (map) {
      map.getLayers().forEach((layer) => {
        if (layer instanceof OlTileLayer) {
          layer.getSource().changed();
        } else {
          layer.changed();
        }
      });
    }
  }, [map, imageSmoothing]);

  // If variableSplitPos changed, adjust Canvas2D clip
  // paths of concerned layers.
  useEffect(() => {
    if (map === null || !isNumber(variableSplitPos)) {
      return;
    }

    // https://openlayers.org/en/latest/examples/layer-swipe.html

    const handlePreRenderL = (event) => {
      setLayerClip(map, event, variableSplitPos, 0);
    };

    const handlePreRenderR = (event) => {
      setLayerClip(map, event, variableSplitPos, 1);
    };

    const handlePostRender = (event) => {
      const ctx = event.context;
      ctx.restore();
    };

    const rgb2Layer = findMapLayer(map, "rgb2");
    const variable2Layer = findMapLayer(map, "variable2");
    const rgbLayer = findMapLayer(map, "rgb");
    const variableLayer = findMapLayer(map, "variable");
    const preRenders = [
      [rgb2Layer, handlePreRenderL],
      [variable2Layer, handlePreRenderL],
      [rgbLayer, handlePreRenderR],
      [variableLayer, handlePreRenderR],
    ];
    for (const [layer, handlePreRender] of preRenders) {
      if (layer) {
        layer.on("prerender", handlePreRender);
        layer.on("postrender", handlePostRender);
      }
    }
    return () => {
      for (const [layer, handlePreRender] of preRenders) {
        if (layer) {
          layer.un("prerender", handlePreRender);
          layer.un("postrender", handlePostRender);
        }
      }
    };
  });

  const handleMapClick = (event) => {
    if (mapInteraction === "Select") {
      const map = event.map;
      let selectedPlaceId = null;
      const features = map.getFeaturesAtPixel(event.pixel);
      if (features) {
        for (const f of features) {
          if (typeof f["getId"] === "function") {
            selectedPlaceId = f["getId"]() + "";
            break;
          }
        }
      }
      if (selectPlace) {
        selectPlace(selectedPlaceId, places, false);
      }
    }
  };

  const handleDrawEnd = (event) => {
    if (map !== null && addDrawnUserPlace && mapInteraction !== "Select") {
      const feature = event.feature;
      let geometry = feature.getGeometry();
      if (!geometry) {
        return;
      }

      const placeId = newId(
        USER_ID_PREFIX + mapInteraction.toLowerCase() + "-",
      );
      const projection = map.getView().getProjection();

      if (geometry instanceof OlCircleGeometry) {
        const polygon = olPolygonFromCircle(geometry );
        feature.setGeometry(polygon);
      }

      // Beware: transform() is an in-place op
      geometry = feature
        .clone()
        .getGeometry()
        .transform(projection, GEOGRAPHIC_CRS);
      const geoJSONGeometry = new OlGeoJSONFormat().writeGeometryObject(
        geometry,
      );
      feature.setId(placeId);
      let colorIndex = 0;
      if (MAP_OBJECTS[USER_DRAWN_PLACE_GROUP_ID]) {
        const userLayer = MAP_OBJECTS[
          USER_DRAWN_PLACE_GROUP_ID
        ];
        const features = userLayer?.getSource()?.getFeatures();
        if (features) colorIndex = features.length;
      }
      const label = findNextLabel(userPlaceGroups, mapInteraction);
      const color = getUserPlaceColorName(colorIndex);
      const shadedColor = getUserPlaceColor(color, theme.palette.mode);
      setFeatureStyle(feature, shadedColor, getUserPlaceFillOpacity());

      addDrawnUserPlace(
        userDrawnPlaceGroupName,
        placeId,
        { label, color },
        geoJSONGeometry,
        true,
      );
    }
    return true;
  };

  function handleMapRef(map) {
    if (onMapRef) {
      onMapRef(map);
    }
    setMap(map);
  }

  const handleDropFiles = (files) => {
    if (importUserPlacesFromText) {
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (typeof reader.result === "string") {
            importUserPlacesFromText(reader.result);
          }
        };
        reader.readAsText(file, "UTF-8");
      });
    }
  };

  return (
    <ErrorBoundary>
      <div id="popup" className={classes.popupContainer}>
        <div id="popup-content" className={classes.popupContent}>
          <p style={{ color: "#b4b4b4" }}>{selectedPlaceInfo?.place?.properties?.group ?? ""}</p>
          <p>{selectedPlaceInfo?.label ?? ""}</p>
          <p style={{ fontWeight: "bold" }}>
            {selectedPlaceInfo?.place?.properties?.total_insured_value
              ? `TIV: $${formatNumber(Number(selectedPlaceInfo?.place?.properties?.total_insured_value.toFixed(2)),'USD')}`
              : ""}
          </p>
        </div>
        <span className={classes.popupArrow}></span>
      </div>
      <Map
        id={mapId}
        onClick={(event) => handleMapClick(event)}
        onMapRef={handleMapRef}
        mapObjects={MAP_OBJECTS}
        isStale={true}
        onDropFiles={handleDropFiles}
      >
        <View id="view" projection={mapProjection} />
        <Layers>
          {baseMapLayer}
          {rgb2Layer}
          {rgbLayer}
          {variable2Layer}
          {variableLayer}
          {overlayLayer}
          {datasetBoundaryLayer}
          <Vector
            id={SELECTION_LAYER_ID}
            opacity={0.7}
            zIndex={500}
            style={SELECTION_LAYER_STYLE}
            source={SELECTION_LAYER_SOURCE}
          />
          {
            <>
              {userPlaceGroups.map((placeGroup) => (
                <UserVectorLayer
                  key={placeGroup.id}
                  placeGroup={placeGroup}
                  mapProjection={mapProjection}
                  visible={showUserPlaces && userPlaceGroupsVisibility[placeGroup.id]}
                />
              ))}
            </>
          }
        </Layers>
        {placeGroupLayers}
        {/*<Select id='select' selectedFeaturesIds={selectedFeaturesId} onSelect={handleSelect}/>*/}
        <Draw
          id="drawPoint"
          layerId={USER_DRAWN_PLACE_GROUP_ID}
          active={mapInteraction === "Point"}
          type={"Point"}
          wrapX={true}
          stopClick={true}
          onDrawEnd={handleDrawEnd}
        />
        <Draw
          id="drawPolygon"
          layerId={USER_DRAWN_PLACE_GROUP_ID}
          active={mapInteraction === "Polygon"}
          type={"Polygon"}
          wrapX={true}
          stopClick={true}
          onDrawEnd={handleDrawEnd}
        />
        <Draw
          id="drawCircle"
          layerId={USER_DRAWN_PLACE_GROUP_ID}
          active={mapInteraction === "Circle"}
          type={"Circle"}
          wrapX={true}
          stopClick={true}
          onDrawEnd={handleDrawEnd}
        />
        {colorBarLegend}
        {colorBarLegend2}
        {mapPointInfoBox}
        {mapSplitter}
        <ScaleLine bar={false} />
      </Map>
    </ErrorBoundary>
  );
}

function findFeatureById(map, featureId) {
  for (const layer of map.getLayers().getArray()) {
    if (layer instanceof OlVectorLayer) {
      const vectorLayer = layer;
      const feature = vectorLayer.getSource()?.getFeatureById(featureId);
      if (feature) {
        return feature;
      }
    }
  }
  return null;
}

function findNextLabel(userPlaceGroups, mapInteraction) {
  const nameBase = i18n.get(mapInteraction);
  const drawingPlaceGroup = userPlaceGroups.find((pg) => pg.id === USER_DRAWN_PLACE_GROUP_ID);
  if (drawingPlaceGroup) {
    for (let index = 1; ; index++) {
      const label = `${nameBase} ${index}`;
      const exists = !!drawingPlaceGroup.features.find((p) => {
        if (!p.properties) {
          return false;
        }
        return p.properties["label"] === label;
      });
      if (!exists) {
        return label;
      }
    }
  }
  return `${nameBase} 1`;
}

function setLayerClip(map, event, splitPos, side) {
  const mapSize = map.getSize();
  if (!mapSize) {
    return;
  }

  const mapWidth = mapSize[0];
  const mapHeight = mapSize[1];

  let tl, tr, bl, br;
  if (side === 0) {
    tl = getRenderPixel(event, [0, 0]);
    tr = getRenderPixel(event, [splitPos, 0]);
    bl = getRenderPixel(event, [0, mapHeight]);
    br = getRenderPixel(event, [splitPos, mapHeight]);
  } else {
    tl = getRenderPixel(event, [splitPos, 0]);
    tr = getRenderPixel(event, [mapWidth, 0]);
    bl = getRenderPixel(event, [splitPos, mapHeight]);
    br = getRenderPixel(event, [mapWidth, mapHeight]);
  }

  const ctx = event.context;
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(tl[0], tl[1]);
  ctx.lineTo(bl[0], bl[1]);
  ctx.lineTo(br[0], br[1]);
  ctx.lineTo(tr[0], tr[1]);
  ctx.closePath();
  ctx.clip();
}
