import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import BarChartIcon from "@mui/icons-material/BarChart";
import CloseIcon from "@mui/icons-material/Close";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import IsoIcon from "@mui/icons-material/Iso";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import ShowChartIcon from "@mui/icons-material/ShowChart";

import i18n from "../../i18n";
import { makeStyles } from "../../util/styles";
import TimeSeriesAddButton from "./TimeSeriesAddButton";

const SHOW_DEV_VALUE = "stddev";

const styles = makeStyles({
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "1px",
  },
  responsiveContainer: {
    flexGrow: "1px",
  },
  actionButton: {
    zIndex: 1000,
    opacity: 0.8,
  },
  chartTitle: {
    fontSize: "inherit",
    fontWeight: "normal",
  },
  chartTypes: (theme) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }),
});

export default function TimeSeriesChartHeader({
  timeSeriesGroup,
  placeGroupTimeSeries,
  addPlaceGroupTimeSeries,
  removeTimeSeriesGroup,
  resetZoom,
  loading,
  zoomed,
  chartType,
  setChartType,
  stdevBarsDisabled,
  stdevBars,
  setStdevBars,
}) {
  const handleChartTypeChange = (
    _event,
    values,
  ) => {
    const valueSet = new Set(values);
    const showStdDevNew = valueSet.has(SHOW_DEV_VALUE);
    valueSet.delete(SHOW_DEV_VALUE);
    valueSet.delete(chartType);
    values = Array.from(valueSet);
    setChartType(
      values.length === 1 ? (values[0] ) : chartType,
    );
    setStdevBars(showStdDevNew);
  };

  return (
    <Box sx={styles.headerContainer}>
      <Box sx={styles.actionsContainer}>
        {zoomed && (
          <Tooltip arrow title={i18n.get("Zoom to full range")}>
            <IconButton
              key={"zoomOutButton"}
              sx={styles.actionButton}
              onClick={resetZoom}
              size="small"
            >
              <FitScreenIcon fontSize={"inherit"} />
            </IconButton>
          </Tooltip>
        )}

        <ToggleButtonGroup
          value={stdevBars ? [chartType, SHOW_DEV_VALUE] : [chartType]}
          onChange={handleChartTypeChange}
          size="small"
          sx={styles.chartTypes}
        >
          <Tooltip arrow title={i18n.get("Show points")}>
            <ToggleButton value="point" size="small">
              <ScatterPlotIcon fontSize="inherit" />
            </ToggleButton>
          </Tooltip>
          <Tooltip arrow title={i18n.get("Show lines")}>
            <ToggleButton value="line" size="small">
              <ShowChartIcon fontSize="inherit" />
            </ToggleButton>
          </Tooltip>
          <Tooltip arrow title={i18n.get("Show bars")}>
            <ToggleButton value="bar" size="small">
              <BarChartIcon fontSize="inherit" />
            </ToggleButton>
          </Tooltip>
          <Tooltip arrow title={i18n.get("Show standard deviation (if any)")}>
            <ToggleButton
              value={SHOW_DEV_VALUE}
              size="small"
              disabled={stdevBarsDisabled}
            >
              <IsoIcon fontSize="inherit" />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>

        <TimeSeriesAddButton
          sx={styles.actionButton}
          timeSeriesGroupId={timeSeriesGroup.id}
          placeGroupTimeSeries={placeGroupTimeSeries}
          addPlaceGroupTimeSeries={addPlaceGroupTimeSeries}
        />

        {loading ? (
          // Note, we show progress instead of the close button,
          // because we can not yet cancel loading time series.
          <CircularProgress
            size={24}
            sx={styles.actionButton}
            color={"secondary"}
          />
        ) : (
          <IconButton
            sx={styles.actionButton}
            aria-label="Close"
            onClick={() => removeTimeSeriesGroup(timeSeriesGroup.id)}
            size="small"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}
