import React, { useEffect, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  CircularProgress,
  Stack,
  IconButton,
  Collapse,
  Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import useSupercluster from "use-supercluster";
import maplibre from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import clsx from "clsx";
import { getCentroid } from "../../Util/getCentroid";

const useStyles = makeStyles(() => ({
  root: {
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    position: "relative"
  },
  map: {
    flex: 1,
  },
  mapPin: {
    '& .maplibregl-canvas-container': {
      cursor: 'url(assets/images/pin-location.png), auto'
    }
  },
  icon: {
    position: "relative",
  },
  label: {
    position: "absolute",
    inset: 0,
    color: "#fff",
    fontSize: "20px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  markerContainer: {
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
  },
  activeMarker: {
    filter: "drop-shadow(0px 2px 5px #98221A)",
  },
  alertContainer: {
    position: "absolute",
    top: 10,
    zIndex: 100,
  },
  alert: {
    backgroundColor: "#1976D2",
    borderRadius: 2,
    color: 'white',
  }
}));

const apiKey = window.appConfig.geoapify.api.key;

const options = {
  radius: 75,
  maxZoom: 15,
  reduce: (acc, props) => {
    acc.count += props.count;

    if (acc.status === "error") {
      return acc;
    }

    if (acc.status === "simulated" && props.status !== 'error') {
      return acc;
    }

    acc.status = props.status;

    return acc;
  },
};

const LAT_OFFSET = 0.05;

const COLOR = {
  Green: "GREEN",
  Red: "RED",
  Yellow: "YELLOW",
};

const getMarkerIcon = (color = COLOR.Green, selected) => {
  switch (color) {
    case COLOR.Green:
      return selected
        ? "assets/images/green-circle-shadow-icon.svg"
        : "assets/images/green-circle-icon.svg";
    case COLOR.Red:
      return selected
        ? "assets/images/red-circle-shadow-icon.png"
        : "assets/images/red-circle-icon.svg";
    case "YELLOW":
      return selected
        ? "assets/images/yellow-circle-shadow-icon.svg"
        : "assets/images/yellow-circle-icon.svg";
    default:
      throw new Error("Invalid color");
  }
};

const getColor = (status) => {
  switch (status) {
    case "error":
      return COLOR.Red;
    case "simulated":
      return COLOR.Yellow;
    case "active":
    default:
      return COLOR.Green;
  }
};

export const CoveragesMap = ({
  isLoading,
  setBounds,
  selectedCoordinates,
  setSelectedCoordinates,
  mapInteraction,
  zoom,
  setZoom,
  coveragesForMap
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [points, setPoints] = useState([]);
  const [boundsCluster, setBoundsCluster] = useState();
  const mapContainer = useRef(null);
  const maplibreRef = useRef();
  const [mapReady, setMapReady] = useState(false);

  const { clusters, supercluster } = useSupercluster({
    points,
    zoom,
    bounds: boundsCluster,
    options: {
      ...options,
    },
  });
  
  useEffect(() => {
    const mapStyle = "https://maps.geoapify.com/v1/styles/osm-carto/style.json";
    const map = new maplibre.Map({
      container: mapContainer.current,
      style: `${mapStyle}?apiKey=${apiKey}`,
      interactive: true,
      zoom: 0,
      maxZoom: 15,
    });

    const updateMap = () => {
      const b = map.getBounds();
      setBoundsCluster([
        b.getSouthWest().lng,
        b.getSouthWest().lat,
        b.getNorthEast().lng,
        b.getNorthEast().lat,
      ]);

      setZoom(map.getZoom());
    };

    map.on("move", () => {
      const mapBounds = map.getBounds();
      const LON_OFFSET = (mapBounds._ne.lng - mapBounds._sw.lng) * 0.02;
      const topLeftLat = mapBounds._sw.wrap().lat - LAT_OFFSET;
      const topLeftLon = mapBounds._ne.wrap().lng + LON_OFFSET;
      const bottomRightLat = mapBounds._ne.wrap().lat - LAT_OFFSET;
      const bottomRightLon = mapBounds._sw.wrap().lng + LON_OFFSET;

      setBounds({
        topLeftLat: topLeftLat.toFixed(1),
        topLeftLon: topLeftLon.toFixed(1),
        bottomRightLat: bottomRightLat.toFixed(1),
        bottomRightLon: bottomRightLon.toFixed(1),
      });
    });

    map.on("load", () => {
      setMapReady(true);
      updateMap();

      map.on("move", updateMap);
    });

    maplibreRef.current = map;

    return () => {
      setMapReady(false);
      maplibreRef.current?.remove?.();
    };
  }, [setBounds, setZoom, mapInteraction]);

  useEffect(() => {
    const map = maplibreRef.current;
    const isReady = !mapContainer.current || !map || !mapReady;

    if (isReady) {
      return;
    }

    const markers = clusters.filter(cluster => cluster.properties?.cluster).map((cluster) => {
      const [lng, lat] = cluster.geometry.coordinates;
      const {cluster: isCluster, count , cluster_id: clusterId, status } = cluster.properties;
      let topLeftLat;
      let topLeftLon;
      let bottomRightLat;
      let bottomRightLon;
      if(isCluster){
        const clusterChildren = supercluster.getLeaves(clusterId, Infinity);
        const coordinates = clusterChildren.map((child) => child.geometry.coordinates);

        const lngs = coordinates.map(([lng]) => lng);
        const lats = coordinates.map(([_, lat]) => lat);

        topLeftLat = Math.max(...lats);
        topLeftLon = Math.min(...lngs);
        bottomRightLat = Math.min(...lats);
        bottomRightLon = Math.max(...lngs);
      }

      const markerContainer = document.createElement("div");
      markerContainer.classList.add(classes.markerContainer);

      const icon = document.createElement("img");
      icon.src = getMarkerIcon(
        getColor(status),
        selectedCoordinates?.topLeftLon === topLeftLon &&
          selectedCoordinates?.topLeftLat === topLeftLat &&
          selectedCoordinates?.bottomRightLat === bottomRightLat &&
          selectedCoordinates?.bottomRightLon === bottomRightLon,
      );
      icon.classList.add(classes.icon);

      const label = document.createElement("div");
      label.classList.add(classes.label);
      markerContainer.appendChild(icon);
      markerContainer.appendChild(label);
      label.innerText = count;

      const marker = new maplibre.Marker({ element: markerContainer });

      markerContainer.addEventListener("click", (e) => {
        e.stopPropagation();
        setSelectedCoordinates({
          topLeftLon,
          topLeftLat,
          bottomRightLon,
          bottomRightLat,
        });
      });

      marker.setLngLat(cluster.geometry.coordinates).addTo(map);
      return marker;
    });

    return () => {
      markers.forEach((marker) => marker.remove());
    };
  }, [
    mapReady,
    selectedCoordinates,
    classes.marker,
    classes.activeMarker,
    classes.markerContainer,
    classes.icon,
    classes.label,
    clusters,
  ]);

  useEffect(() => {
    const fetchPoints = async () => {
      const features =
        coveragesForMap
          .map((coverage) => ({
            coordinates:
              coverage?.monitoringTarget?.geometryData?.geoJson?.features?.[0]?.geometry
                ?.coordinates,
            monitoringTargetId: coverage?.monitoringTarget?.id,
            coverage: coverage,
          }))
          
      const latValues = [...new Set(features.map(({coordinates}) => coordinates?.[0]?.map((coordinate) => coordinate?.[0])).flat(2))];
      const lonValues = [...new Set(features.map(({coordinates}) => coordinates?.[0]?.map((coordinate) => coordinate?.[1])).flat(2))];

      if (!!latValues.length && !!lonValues.length) {
        const bounds = [[Math.min(...latValues) - 1, Math.min(...lonValues) - 1], [Math.max(...latValues) + 1, Math.max(...lonValues) + 1]];
        const map = maplibreRef.current;
        if (!!map) {
          map.fitBounds(bounds);
        }
      }

      const transformedFeatures =
        features
          .map((item) => {
            const isNestedArray = Array.isArray(item?.coordinates?.[0]?.[0]);

            return {
              points: getCentroid(
                isNestedArray
                  ? Array.isArray(item?.coordinates?.[0]?.[0][0])
                    ? item.coordinates[0][0]
                    : item.coordinates[0]
                  : [item.coordinates],
              ),
              monitoringTargetId: item.monitoringTargetId,
              coverage: item.coverage,
            };
          }) ?? [];

      const pointsWithCounts = transformedFeatures.map((feature) => {
        return {
          type: "Feature",
          properties: {
            monitoringTargetId: feature.monitoringTargetId,
            cluster: false,
            count: 1,
            status: feature.coverage?.uiState,
          },
          geometry: {
            type: "Point",
            coordinates: feature.points,
          },
        };
      });

      setPoints(pointsWithCounts);
    };

    fetchPoints();
  }, [coveragesForMap]);

  return isLoading ? (
    <Stack flex={1} alignItems="center" justifyContent="center">
      <CircularProgress />
    </Stack>
  ) : (
    <div className={classes.root} style={{height: selectedCoordinates ? "calc(100% - 450px)" : "calc(100% - 145px)"}}>
      <Collapse className={classes.alertContainer} in={open}>
        <Stack direction="row" alignItems="center" className={classes.alert} sx={{py: 2, px: 3}}>
          <InfoIcon fontSize="inherit" />
          <Typography component="span" color="white" fontSize="14px" lineHeight="1" sx={{mx: 1.25}}>Click on clusters to explore available coverages in this region!</Typography>
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Stack>
      </Collapse>
      <div
        className={clsx(classes.map, mapInteraction === "pin" && classes.mapPin)}
        ref={mapContainer}
      />
    </div>
  );
};
