import ColorBarCanvas from "./ColorBarCanvas";
import ColorBarLabels from "./ColorBarLabels";


export default function ColorBarLegendScalable({
  variableColorBar,
  variableColorBarMinMax,
  variableColorBarNorm,
  variableOpacity,
}) {

  return (
    <>
      <ColorBarCanvas
        colorBar={variableColorBar}
        opacity={variableOpacity}
      />
      <ColorBarLabels
        minValue={variableColorBarMinMax[0]}
        maxValue={variableColorBarMinMax[1]}
        numTicks={5}
        logScaled={variableColorBarNorm === "log"}
      />
    </>
  );
}
