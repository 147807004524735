import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { setSidebarPosition } from "../actions/controlActions";
import Viewer from "./Viewer";
import Sidebar from "./Sidebar";
import { useTheme } from "@mui/material";

const styles = {
  container: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    height: "100%",
  },
  viewer: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  sidebar: (sidebarOpen) => ({
    position: "absolute",
    top: 0,
    bottom: 0,
    right: sidebarOpen ? 0 : "-500px",
    width: "500px",
    transition: "right 0.3s ease",
    overflowY: "auto",
    backgroundColor: "#f4f4f4",
    zIndex: 1001,
  }),
};

const mapStateToProps = (state) => ({
  sidebarOpen: state.controlState.sidebarOpen,
});

const mapDispatchToProps = {
  setSidebarPosition,
};

function _Workspace({ sidebarOpen }) {
  const [map, setMap] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    if (map) {
      map.updateSize();
    }
  }, [map, sidebarOpen]);

  return (
    <div style={styles.container}>
      <div style={styles.viewer}>
        <Viewer onMapRef={setMap} theme={theme} />
      </div>
      <div style={styles.sidebar(sidebarOpen)}>
        <Sidebar />
      </div>
    </div>
  );
}

const Workspace = connect(mapStateToProps, mapDispatchToProps)(_Workspace);
export default Workspace;
