

import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";

import i18n from "../i18n";
import { UNIT } from "../model/timeSeries";
import {
  utcTimeToIsoDateTimeString,
} from "../util/time";
import makeStyles from '@mui/styles/makeStyles';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { localToUtcTime, utcTimeToLocal } from "../util/time";
import InputLabel from "@mui/material/InputLabel/InputLabel";

// noinspection JSUnusedLocalSymbols
const useStyles = makeStyles((theme) => ({
  box: {
    minWidth: '350px',
    paddingBottom: '5px'
  },
  dateTimePickerMin: {
    maxWidth: '150px',
  },
  dateTimePickerMax: {
    maxWidth: '150px'
  },
  boxDate: {
    display: "flex",
    justifyContent: 'space-between'
  },
  boxPicker: {
    mb: 10
  }
}));

export default function TimeSlider({
  hasTimeDimension,
  selectedTime,
  selectTime,
  selectedTimeRange,
  selectTimeRange
}) {
  const [selectedTime_, setSelectedTime_] = useState(selectedTime);
  const classes = useStyles();

  const handleTimeChange = (date, label) => {
    const value = date !== null ? localToUtcTime(date) : null
    if(label === 'min'){
      selectTimeRange([value, selectedTimeRange[1]]);
      return;
    }
    selectTimeRange([selectedTimeRange[0], value]);
  };


  useEffect(() => {
    setSelectedTime_(
      selectedTime || (selectedTimeRange ? selectedTimeRange[0] : 0),
    );
  }, [selectedTime, selectedTimeRange]);

  if (!hasTimeDimension) {
    return null;
  }

  const handleChange = (_event, value) => {
    if (typeof value === "number") {
      setSelectedTime_(value);
    }
  };

  const handleChangeCommitted = (
    _event,
    value,
  ) => {
    if (selectTime && typeof value === "number") {
      selectTime(value);
    }
  };

  const selectedTimeRangeValid = Array.isArray(selectedTimeRange);
  if (!selectedTimeRangeValid) {
    selectedTimeRange = [Date.now() - 2 * UNIT.years, Date.now()];
  }

  function valueLabelFormat(value) {
    return utcTimeToIsoDateTimeString(value);
  }

  let minTimeValue, maxTimeValue;
  if (Array.isArray(selectedTimeRange)) {
    minTimeValue = utcTimeToLocal(selectedTimeRange[0]);
    maxTimeValue = utcTimeToLocal(selectedTimeRange[1]);
  }

  const timeMinInput = (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box className={classes.boxPicker}>
        <InputLabel shrink sx={{fontSize: 14}}>
          Min Date
        </InputLabel>
        <DatePicker
          disabled={!hasTimeDimension}
          className={classes.dateTimePickerMin}
          inputFormat="yyyy-MM-dd"
          value={minTimeValue}
          onChange={(value, context) => handleTimeChange(value, "min")}
          ampm={false}
          renderInput={(props) => <TextField {...props} variant="standard" size="small" />}
          slotProps={{ textField: { size: "small" } }}
        />
      </Box>
    </LocalizationProvider>
  );

  const timeMaxInput = (
    <LocalizationProvider dateAdapter={AdapterDateFns} >
      <Box className={classes.boxPicker}>
        <InputLabel shrink sx={{fontSize: 14}}>
          Max Date
        </InputLabel>
        <DatePicker
          disabled={!hasTimeDimension}
          inputFormat="yyyy-MM-dd"
          value={maxTimeValue}
          className={classes.dateTimePickerMax}
          onChange={(value, context) => handleTimeChange(value, "max")}
          ampm={false}
          renderInput={(props) => <TextField {...props} variant="standard" size="small" />}
          slotProps={{ textField: { size: "small" } }}
        />
      </Box>
    </LocalizationProvider>
  );

  return (
    <Box className={classes.box}>
      <Tooltip arrow title={i18n.get("Select time in dataset")}>
        <Slider
          disabled={!selectedTimeRangeValid}
          min={selectedTimeRange[0]}
          max={selectedTimeRange[1]}
          value={selectedTime_ || 0}
          valueLabelDisplay="off"
          valueLabelFormat={valueLabelFormat}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
          size="small"
        />
      </Tooltip>
      <Box className={classes.boxDate}>
        {timeMinInput}
        {timeMaxInput}
      </Box>
    </Box>
  );
}
