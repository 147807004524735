import { useRef, useState } from "react";
import Box from "@mui/material/Box";

import { makeStyles } from "../../util/styles";
import ColorBarLegendCategorical from "./ColorBarLegendCategorical";
import ColorBarLegendScalable from "./ColorBarLegendScalable";
import Typography from "@mui/material/Typography";
import { COLOR_BAR_ITEM_WIDTH } from "./constants";

const styles = makeStyles({
  container: (theme) => ({
    position: "absolute",
    zIndex: 1000,
    top: 10,
    borderRadius: "5px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#00000020",
    backgroundColor: "#FFFFFFAA",
    color: "black",
    maxWidth: `${COLOR_BAR_ITEM_WIDTH + 20}px`,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
  }),
  title: (theme) => ({
    fontSize: "small",
    fontWeight: "bold",
    width: "100%",
    display: "flex",
    wordBreak: "break-word",
    wordWrap: "break-word",
    justifyContent: "center",
    paddingBottom: theme.spacing(0.5),
  }),
});


export default function ColorBarLegend(
  props,
) {
  const {
    variableName,
    variableTitle,
    variableUnits,
    variableColorBar,
    style,
  } = props;

  const colorBarSelectAnchorRef = useRef(null);

  if (!variableName) {
    return null;
  }

  const variableTitleWithUnits =
    variableColorBar.type === "categorical"
      ? variableTitle || variableName
      : `${variableTitle || variableName} (${variableUnits || "-"})`;

  return (
    <Box sx={styles.container} style={style} ref={colorBarSelectAnchorRef}>
      <Typography sx={styles.title}>{variableTitleWithUnits}</Typography>
      {variableColorBar.type === "categorical" ? (
        <ColorBarLegendCategorical
          categories={variableColorBar.colorRecords}
          {...props}
        />
      ) : (
        <ColorBarLegendScalable {...props} />
      )}
    </Box>
  );
}
