import * as React from "react";
import FormControl from "@mui/material/FormControl";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import CategoryIcon from "@mui/icons-material/Category";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import i18n from "../i18n";
import { commonStyles } from "./common-styles";
import { Box } from "@mui/material";
export default function MapInteractionsBar({ mapInteraction, setMapInteraction }) {
  function handleChange(_event, value) {
    if (value !== null) {
      setMapInteraction(value);
    } else {
      setMapInteraction("Select");
    }
  }

  return (
    <Box>
      <InputLabel shrink>Custom Geometry</InputLabel>
      <FormControl variant="standard">
        <ToggleButtonGroup size="small" value={mapInteraction} exclusive onChange={handleChange}>
          <ToggleButton key={0} value="Select" size="small" sx={commonStyles.toggleButton}>
            <Tooltip arrow title={i18n.get("Select a place in map")}>
              <AdsClickIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton key={1} value="Point" size="small" sx={commonStyles.toggleButton}>
            <Tooltip arrow title={i18n.get("Add a point location in map")}>
              <AddLocationIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton key={2} value="Polygon" size="small" sx={commonStyles.toggleButton}>
            <Tooltip arrow title={i18n.get("Draw a polygon area in map")}>
              <CategoryIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton
          key={3}
          value="Circle"
          size="small"
          sx={commonStyles.toggleButton}
        >
          <Tooltip arrow title={i18n.get("Draw a circular area in map")}>
            <FiberManualRecordIcon />
          </Tooltip>
        </ToggleButton>
        </ToggleButtonGroup>
      </FormControl>
    </Box>
  );
}
