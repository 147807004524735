import { Autocomplete, Button, CircularProgress, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useRef, useState } from "react";
import { useGeocoding } from "../../hooks/googleMaps/useGeocoding";
import { useDebounce } from "../../Util/useDebounce";

const useStyles = makeStyles({
  findAddressButton: {
    whiteSpace: "nowrap",
    flexShrink: 0,
  },
});

export const GeocodingAutocomplete = ({
  label,
  onChange,
  placeholder,
  clickToFind = false,
  onClickToFind,
  countryCode,
  address: addressProp,
}) => {
  const classes = useStyles();
  const isAllowedToFetch = useRef(false);
  const [options, setOptions] = useState([]);
  const [address, setAddress] = useState("");
  const debouncedAddress = useDebounce(address, 500);
  const { data, refetch } = useGeocoding(debouncedAddress, countryCode);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!data) {
      return;
    }

    setOptions(data.features ?? []);
  }, [data]);

  useEffect(() => {
    if (!address) {
      setOptions([]);
    }
  }, [address]);

  useEffect(() => {
    if (isAllowedToFetch.current && debouncedAddress && !clickToFind) {
      refetch();
    }
  }, [debouncedAddress, refetch, clickToFind]);

  useEffect(() => {
    isAllowedToFetch.current = false;
    setAddress(addressProp ?? "");
  }, [addressProp]);

  const handleFindAddress = async (e) => {
    if (!clickToFind) {
      return;
    }

    e.stopPropagation();

    if (address) {
      try {
        setIsLoading(true);
        const { data } = await refetch();
        if (data.features.length > 0) {
          onClickToFind?.(data.features[0]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Autocomplete
      onChange={(_, value) => {
        onChange?.(value);
      }}
      disablePortal
      freeSolo
      options={clickToFind ? [] : options}
      getOptionLabel={(option) => option.properties.formatted}
      filterOptions={(options) => options}
      disableClearable
      inputValue={address}
      onInputChange={(_, value) => {
        isAllowedToFetch.current = true;
        setAddress(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            ...(clickToFind && {
              endAdornment: isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <Button className={classes.findAddressButton} onClick={handleFindAddress}>
                  Find Address
                </Button>
              ),
            }),
            onKeyDown: (e) => {
              if (e.key === "Enter") {
                e.preventDefault()
                e.stopPropagation()
              }
            },
          }}
        />
      )}
    />
  );
};
