import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import ToolboxCoverages from "../Toolbox/ToolboxCoverages";
import CoveragesData from "./CoveragesData";
import CoveragesActivate from "./CoveragesActivate";
import { CoveragesMap } from "./CoveragesMap";

const Coverages = (props) => {
  const {
    selectedStatus,
    onSelectStatus,
    coverages,
    loaded,
    page,
    rowsPerPage,
    totalPages,
    totalElements,
    onChangePage,
    onReload,
    refreshLoading,
    onSearchChange,
    selected,
    onSelect,
    handleActivate,
    selectedCoordinates,
    setSelectedCoordinates,
    coveragesForMap
  } = props;

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      overflow: "auto",
    },
  }));
  const [openActivate, setOpenActivate] = React.useState(false);
  const [bounds, setBounds] = React.useState();
  const [zoom, setZoom] = React.useState(12);
  const [view, setView] = React.useState("map");
  const [mapInteraction, setMapInteraction] = React.useState("click");

  const classes = useStyles();

  const handleActivateOpen = () => {
    setOpenActivate(true);
  };

  const handleActivateClose = (object, reason) => {
    if (reason !== "backdropClick") {
      setOpenActivate(false);
    }
  };

  const handleView = (
    event,
    newView,
  ) => {
    setView(newView);
    if (newView === 'list') {
      setSelectedCoordinates(null);
    }
  };

  const handleInteraction = (
    event,
    newInteraction,
  ) => {
    setMapInteraction(newInteraction);
  };

  return (
    <Box className={classes.root}>
      <Toolbar />
      <ToolboxCoverages
        selected={selected}
        handleOpen={handleActivateOpen}
        onReload={onReload}
        refreshLoading={refreshLoading}
        onSearchChange={onSearchChange}
        view={view}
        handleView={handleView}
        mapInteraction={mapInteraction}
        handleInteraction={handleInteraction}
      />
      <CoveragesActivate
        open={openActivate}
        handleActivate={handleActivate}
        handleClose={handleActivateClose}
      />
      {view === "map" ? (
        <CoveragesMap
          coverages={coverages}
          bounds={bounds}
          setBounds={setBounds}
          mapInteraction={mapInteraction}
          zoom={zoom}
          setZoom={setZoom}
          selectedCoordinates={selectedCoordinates}
          setSelectedCoordinates={setSelectedCoordinates}
          coveragesForMap={coveragesForMap}
        />
      ) : null}
      {(view === "list" || !!selectedCoordinates) && (
        <CoveragesData
          {...{
            coverages,
            loaded,
            page,
            rowsPerPage,
            totalPages,
            totalElements,
            onChangePage,
            selected,
            onSelect,
            selectedStatus,
            onSelectStatus,
          }}
        />
      )}
    </Box>
  );
};

export default Coverages;
