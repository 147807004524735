import React, { useContext, useRef,  } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ContextTheme from '../../Contexts/Theme';
import Time from '../../Util/time';
import String from '../../Util/string';
import Format from '../../Util/format';
import formatMissingValue from '../../Util/formatMissingValue';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  card: {
    position: 'relative',
    padding: theme.spacing(2.5),
    backgroundColor: theme.palette.common.white,
    minHeight: 100,
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    boxShadow: '0px 3px 12px 0px #0000001F',
    flex: 1,
  },
  cardItem: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      padding: '8px 18px',
    },
  },
  divider: {
    opacity: 0.15,
  },
  downloadButton: {
    borderRadius: 8,
    borderColor: theme.palette.text.hint,
    color: theme.palette.text.secondary,
  },
  disclaimer: {
    textAlign: "center",
    padding: 16,
    fontWeight: 600,
    fontSize: 16,
  },
  note: {
    textAlign: 'center',
    fontSize: '14px',
    marginBottom: 8,
  },
  table: {
    borderCollapse: 'collapse',
    marginTop: 16,
  },
  tableCell: {
    border: `2px solid ${theme.palette.starDust}`,
  },
  tableValue: {
    backgroundColor: theme.palette.seaShell,
  },
  tableHeading: {
    backgroundColor: theme.palette.greyGoose,
  },
  indexTracker: {
    color: theme.palette.blueDress,
  },
  indexDefinition: {
    color: theme.palette.blackCow,
  }
}));

const formatCurrency = (currency, amount) =>
  `${currency} ${Number(amount.toFixed(2)).toLocaleString()}`;

const InformationAndCoverage = (props) => {
  const {
    customerName,
    inceptionDate,
    expiryDate,
    grossWrittenPremium,
    coverages,
    title,
    status,
    currency,
    issuer,
    description,
    intermediary,
    grossWrittenPremiumPct,
    type
  } = props;
  const { locale } = useContext(ContextTheme);
  const classes = useStyles();
  const totalSumInsured = coverages?.reduce((acc, val) => acc + val.totalSumInsured.amount, 0) ?? 0;
  const componentRef = useRef(null);

  const coverageContent = coverages.length ? (
    coverages.map((coverage, index) => {
      const {
        id,
        indexDefinition,
        cohortName,
        sumInsuredIndividual,
        trigger,
        minPayout,
        minPayoutPct,
        totalSumInsured,
        payoutPerUnitPct,
        pricingUnit,
        quantity,
        dataSourceDescription,
      } = coverage;

      const coveragesLen = coverages?.length;
      const calculatedMinPayout = minPayout.amount * 0.01 * sumInsuredIndividual.amount * quantity;

      return (
        <div key={id}>
          <div className={classes.cardItem}>
            <Typography>
              <Box component="span" fontWeight="fontWeightBold">
                {indexDefinition?.name}
              </Box>
            </Typography>
          </div>
          <div className={classes.cardItem}>
            <Typography>Cohort name</Typography>
            <Typography align="right">{cohortName || "---"}</Typography>
          </div>
          <div className={classes.cardItem}>
            <Typography>Trigger</Typography>
            <Typography align="right">{trigger || "---"}</Typography>
          </div>
          <div className={classes.cardItem}>
            <Typography>Sum Insured Per Insured Extend</Typography>
            <Typography align="right">
              {sumInsuredIndividual.amount
                ? formatCurrency(sumInsuredIndividual?.currency, sumInsuredIndividual?.amount)
                : "---"}
            </Typography>
          </div>
          <div className={classes.cardItem}>
            <Typography>Insured Extend</Typography>
            <Typography align="right">{Format.number(quantity) || "---"}</Typography>
          </div>
          <div className={classes.cardItem}>
            <Typography>Sum Insured</Typography>
            <Typography align="right">
              {totalSumInsured.amount ? formatCurrency(totalSumInsured.currency, totalSumInsured.amount) : "---"}
            </Typography>
          </div>
          <div className={classes.cardItem}>
            <Typography>Minimum Payout</Typography>
            <Typography align="right">
              ({minPayoutPct * 100}%){" "}
              {calculatedMinPayout !== undefined
                ? formatCurrency(currency, calculatedMinPayout)
                : "---"}
            </Typography>
          </div>
          <div className={classes.cardItem}>
            <Typography>Payout per {pricingUnit}</Typography>
            <Typography align="right">
              ({Number((payoutPerUnitPct ?? 0) * 100).toFixed(2)}%){" "}
              {totalSumInsured.amount !== undefined ? formatCurrency(currency, payoutPerUnitPct * totalSumInsured.amount) : "---"}
            </Typography>
          </div>
          <div className={classes.cardItem}>
            <Typography>Data Source</Typography>
            <Typography align="right">{formatMissingValue(dataSourceDescription)}</Typography>
          </div>
          {/* Render horizontal rule only if not last item*/}
          {coveragesLen - 1 !== index ? <hr className={classes.divider} /> : null}
        </div>
      );
    })
  ) : (
    <div className={classes.cardItem}>
      <Typography>No coverages</Typography>
    </div>
  );

  const content = (
    <>
      <Grid container spacing={4}>
        <Grid xs={12} lg={6}>
          <Typography variant="h5" className={classes.title}>
            Basic Information
          </Typography>
          <Stack sx={{ mt: 4 }} rowGap={2.5}>
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">{type} Name</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant={"body1"} fontWeight={600}>
                  {formatMissingValue(title)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">{type} Issuer</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {formatMissingValue(issuer)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">{type} Holder Name</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {formatMissingValue(customerName)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">Intermediary</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {formatMissingValue(intermediary)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">Inception Date</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {inceptionDate ? Time.getDate(inceptionDate, locale) : "---"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">Expiry Date</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {expiryDate ? Time.getDate(expiryDate, locale) : "---"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">Status</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {String.capitalize(status) || "---"}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid xs={12} lg={6}>
          <Typography variant="h5" className={classes.title}>
            Description
          </Typography>
          <Stack sx={{ mt: 4 }} rowGap={2.5}>
            {description
              ?.split("\n")
              .filter(Boolean)
              .map((item) => {
                const [key, val] = item.split(":");

                return (
                  <Grid container key={key}>
                    <Grid sm={5}>
                      <Typography variant="body1">{key}</Typography>
                    </Grid>
                    <Grid sm={7}>
                      <Typography variant="body1" fontWeight={600}>
                        {formatMissingValue(val)}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
          </Stack>
        </Grid>
        <Grid xs={12} lg={6}>
          <Typography variant="h5" className={classes.title}>
            Premiums
          </Typography>
          <Stack sx={{ mt: 4 }} rowGap={2.5}>
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">Premium Amount</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {formatMissingValue(grossWrittenPremium)}
                </Typography>
              </Grid>
            </Grid>
            {/*<Grid container>*/}
            {/*  <Grid sm={5}>*/}
            {/*    <Typography variant="body1">Average Premium Rate (%)</Typography>*/}
            {/*  </Grid>*/}
            {/*  <Grid sm={7}>*/}
            {/*    <Typography variant="body1" fontWeight={600}>*/}
            {/*      {avgGrossWrittenPremiumPct && `${avgGrossWrittenPremiumPct}`}*/}
            {/*    </Typography>*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">Premium Rate (%)</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {grossWrittenPremiumPct}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">Total Sum Insured</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {currency} {Number(totalSumInsured.toFixed(2)).toLocaleString() || "---"}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid xs={12}>
          <Typography variant="h5" className={classes.title}>
            {type} Details
          </Typography>
          <Stack sx={{ mt: 2.5 }}>{coverageContent}</Stack>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Container className={classes.root} maxWidth="xl">
      <Stack spacing={2}>
        <Box className={classes.card} ref={componentRef}>
          {content}
        </Box>
      </Stack>
    </Container>
  );
};

export default InformationAndCoverage;
