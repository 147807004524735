import { Config } from "../config";
import { defaultBaseMapId } from "../model/layerDefinition";
import { DEFAULT_MAP_CRS } from "../model/proj";
import { defaultCsvOptions } from "../model/user-place/csv";
import { defaultGeoJsonOptions } from "../model/user-place/geojson";
import { defaultWktOptions } from "../model/user-place/wkt";
import { loadUserSettings } from "./userSettings";

export const TIME_ANIMATION_INTERVALS = [250, 500, 1000, 2500];

export const sidebarPanelIds = ["timeSeries"];

export function newControlState() {
  const branding = Config.instance.branding;
  const state = {
    selectedDatasetId: null,
    selectedVariableName: null,
    selectedDataset2Id: null,
    selectedVariable2Name: null,
    selectedPlaceGroupIds: [],
    selectedPlaceId: null,
    selectedUserPlaceId: null,
    selectedServerId: Config.instance.server.id,
    selectedTime: null,
    selectedTimeRange: null,
    timeSeriesUpdateMode: "add",
    timeAnimationActive: false,
    timeAnimationInterval: 1000,
    timeChunkSize: 20,
    autoShowTimeSeries: true,
    timeSeriesChartTypeDefault: "line",
    timeSeriesIncludeStdev: true,
    timeSeriesUseMedian: branding.defaultAgg === "median",
    userDrawnPlaceGroupName: "",
    userPlacesFormatName: "csv",
    userPlacesFormatOptions: {
      csv: { ...defaultCsvOptions },
      geojson: { ...defaultGeoJsonOptions },
      wkt: { ...defaultWktOptions },
    },
    flyTo: null,
    activities: {},
    locale: "en",
    dialogOpen: {},
    privacyNoticeAccepted: true,
    mapInteraction: "Select",
    lastMapInteraction: "Select",
    layerVisibilities: {
      baseMap: true,
      datasetRgb: false,
      datasetVariable: true,
      datasetVariable2: true,
      datasetBoundary: false,
      datasetPlaces: true,
      userPlaces: true,
      overlay: true,
    },
    variableCompareMode: false,
    mapPointInfoBoxEnabled: false,
    topBarEnabled: true,
    isSelectAll: true,
    datasetLocateMode: "panAndZoom",
    placeLocateMode: "panAndZoom",
    layerMenuOpen: false,
    sidebarPosition: (2 * Math.max(window.innerWidth, window.innerHeight)) / 3,
    sidebarOpen: false,
    sidebarPanelId: "timeSeries",
    volumeRenderMode: "mip",
    volumeStates: {},
    infoCardElementStates: {
      dataset: { visible: true, viewMode: "text" },
      variable: { visible: true, viewMode: "text" },
      place: { visible: true, viewMode: "text" },
    },
    mapProjection: branding.mapProjection || DEFAULT_MAP_CRS,
    imageSmoothingEnabled: false,
    selectedBaseMapId: defaultBaseMapId,
    selectedOverlayId: null,
    userBaseMaps: [],
    userOverlays: [],
    userColorBars: [],
    exportTimeSeries: true,
    exportTimeSeriesSeparator: "TAB",
    exportPlaces: true,
    exportPlacesAsCollection: true,
    exportZipArchive: true,
    exportFileName: "export",
  };
  return loadUserSettings(state);
}

// We cannot keep "MAP_OBJECTS" in control state object, because these
// objects are (1) not serializable and (2) logging actions will cause
// the browsers to crash

export const MAP_OBJECTS = {};
