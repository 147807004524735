import { createContext, useContext, useState } from "react";

const SidebarContext = createContext({
  isOpen: true,
  setIsOpen: () => {},
});

export const useSidebarContext = () => useContext(SidebarContext);

export const SidebarContextProdiver = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <SidebarContext.Provider value={{ isOpen, setIsOpen }}>{children}</SidebarContext.Provider>
  );
};

export default SidebarContext;
