
import * as React from "react";
import { connect } from "react-redux";
import {
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";

import { Config } from "../config";
import AppBar from "./AppBar";
import AppPane from "./AppPane";
import LoadingDialog from "./LoadingDialog";
import MessageLog from "./MessageLog";
import ServerDialog from "./ServerDialog";
import SettingsDialog from "./SettingsDialog";
import ExportDialog from "./ExportDialog";
import UserPlacesDialog from "./UserPlacesDialog";
import UserLayersDialog from "./UserLayersDialog";
import UserVariablesDialog from "./UserVariablesDialog";
import AuthWrapper from "../components/AuthWrapper";
import { updateResources } from "../actions/dataActions";

const mapStateToProps = (_state) => {
  return {
    compact: Config.instance.branding.compact,
  };
};

const mapDispatchToProps = {
  updateResources,
};

const newTheme = () =>
  createTheme({
    typography: {
      fontSize: 12,
      htmlFontSize: 14,
    },
    palette: {
      mode: Config.instance.branding.themeName,
      primary: Config.instance.branding.primaryColor,
      secondary: Config.instance.branding.secondaryColor,
    },
  });

const _App = ({ compact, updateResources }) => {
  React.useEffect(() => {
    updateResources(false)
  },[updateResources])

  return (
    <AuthWrapper>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={newTheme()}>
          <CssBaseline />
          {!compact && <AppBar />}
          <AppPane />
          <>
            <LoadingDialog />
            <ServerDialog />
            <SettingsDialog />
            <UserLayersDialog key="userOverlays" dialogId="userOverlays" />
            <UserLayersDialog key="userBaseMaps" dialogId="userBaseMaps" />
            <UserVariablesDialog />
            <UserPlacesDialog />
            <ExportDialog />
            <MessageLog />
          </>
        </ThemeProvider>
      </StyledEngineProvider>
    </AuthWrapper>
  );
};

const App = connect(mapStateToProps, mapDispatchToProps)(_App);
export default App;
