import React, { Component } from 'react';
import queryStr from '../../Util/queryString';
import Coverages from './Coverages';
import Ajax, { GetToken } from '../../Util/ajax';

class CoveragesContainer extends Component {
  constructor () {
    super();

    this.API = {
      getUri: `${window.appConfig.apiUrl}/internal/coverages`,
      findByBoundingBox: `${window.appConfig.apiUrl}/internal/coverages/find-by-bounding-box`,
    };

    this.state = {
      status: 'all',
      coverages: [],
      loaded: false,
      refreshLoading: false,
      page: 0,
      rowsPerPage: 15,
      totalPages: 0,
      totalElements: 0,
      search: '',
      selected: [],
      selectedCoordinate: null,
      coveragesForMap: [],
    };

    this.command = queryStr('command');

    // Method binding.
    this.getCoverages = this.getCoverages.bind(this);
    this.handleReload = this.handleReload.bind(this);
    this.handleSelectStatus = this.handleSelectStatus.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleActivate = this.handleActivate.bind(this);
    this.updateSelectedCoordinate = this.updateSelectedCoordinate.bind(this);
  }

  getCoverages() {
    this.setState(
      {
        loaded: false,
      },
      () => {
        if (!this.state.coveragesForMap?.length) {
          let API_URL = `${this.API.findByBoundingBox}?bottomRightLon=180&bottomRightLat=-90&topLeftLon=-180&topLeftLat=90`;
          GetToken().then((token) => {
            Ajax.getData(API_URL, token)
              .then((data) => {
                this.setState({
                  coveragesForMap: data,
                  loaded: true,
                  refreshLoading: false,
                });
              })
              .catch((error) => {
                console.log("error", error);
              });
          });
        }

        if (this.state.selectedCoordinate) {
          let API_URL = `${this.API.findByBoundingBox}?bottomRightLon=${this.state.selectedCoordinate.bottomRightLon}&bottomRightLat=${this.state.selectedCoordinate.bottomRightLat}&topLeftLon=${this.state.selectedCoordinate.topLeftLon}&topLeftLat=${this.state.selectedCoordinate.topLeftLat}`;
          GetToken().then((token) => {
            Ajax.getData(API_URL, token)
              .then((data) => {
                const filteredData = this.state.status === 'all' ? data : data.filter(coverage => coverage.uiState === this.state.status);
                this.setState({
                  coverages: filteredData ?? [],
                  loaded: true,
                  refreshLoading: false,
                  totalPages: 1,
                  totalElements: filteredData?.length ?? 0,
                });
              })
              .catch((error) => {
                console.log("error", error);
              });
          });

          return;
        }

        let API_URL = `${this.API.getUri}?page=${this.state.page}&size=${this.state.rowsPerPage}&state=${this.state.status}`;
        if (this.state.search) {
          API_URL += `&targetName=${this.state.search}`;
        }
        GetToken().then((token) => {
          Ajax.getData(API_URL, token)
            .then((data) => {
              this.setState({
                coverages: data?.content ?? [],
                loaded: true,
                refreshLoading: false,
                totalPages: data?.totalPages ?? 0,
                totalElements: data?.totalElements ?? 0,
              });
            })
            .catch((error) => {
              console.log("error", error);
            });
        });
      },
    );
  }

  handleSearchChange(newValue) {
    this.setState(
      {
        search: newValue,
        page: 0,
      },
      () => {
        this.getCoverages();
      },
    );
  }

  handlePageChange(event, value) {
    this.setState(
      {
        page: value,
      },
      () => {
        this.getCoverages();
      },
    );
  }

  handleReload() {
    this.setState(
      {
        loaded: false,
        refreshLoading: true,
        tabIndex: 0,
        page: 0,
      },
      () => {
        this.getCoverages();
      },
    );
  }

  handleActivate() {
    this.setState(
      {
        backdropOpen: true,
      },
      () => {
        Promise.all(
          this.state.selected.map(({ id }) => {
            const URI = `${window.appConfig.apiUrl}/internal/coverages/${id}/activate`;

            GetToken().then((token) => {
              Ajax.postData(URI, {}, token).catch((error) => {
                this.setState({
                  ajaxError: true,
                  ajaxErrorModalOpen: true,
                });
                console.error("error", error);
              });
            });
          }),
        )
          .then(() => {
            this.setState({
              selected: [],
            });
            this.getCoverages();
          })
          .finally(() => {
            this.setState({
              backdropOpen: false,
            });
          });
      },
    );
  }

  handleSelectStatus(status) {
    this.setState({ status }, () => {
      this.getCoverages();
    });
  }

  updateSelectedCoordinate(coordinate) {
    this.setState({ selectedCoordinate: coordinate });
    this.getCoverages();
  }

  componentDidMount() {
    this.getCoverages();
  }

  render() {
    const { coverages, loaded, refreshLoading, status } = this.state;

    return (
      <Coverages
        selectedStatus={status}
        onReload={this.handleReload}
        refreshLoading={refreshLoading}
        coverages={coverages}
        loaded={loaded}
        page={this.state.page}
        rowsPerPage={this.state.rowsPerPage}
        totalElements={this.state.totalElements}
        totalPages={this.state.totalPages}
        onChangePage={this.handlePageChange}
        onSearchChange={this.handleSearchChange}
        onSelectStatus={this.handleSelectStatus}
        selected={this.state.selected}
        onSelect={(newVal) => this.setState({ selected: newVal })}
        handleActivate={this.handleActivate}
        selectedCoordinates={this.state.selectedCoordinate}
        setSelectedCoordinates={(coordinate) => this.updateSelectedCoordinate(coordinate)}
        coveragesForMap={this.state.status === 'all' ? this.state.coveragesForMap : this.state.coveragesForMap.filter(coverage => coverage.uiState === this.state.status)}
      />
    );
  }
}

export default CoveragesContainer;
